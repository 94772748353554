import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicy1 from "../sections/privacy-policy/privacy-policy-1"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy  "
        description="We care deeply about protecting personal information and take great care in making sure it is kept private and secure. Find the SuperBetter privacy policy here.
"
        keywords=" SuperBetter Privacy Policy"
      />
      <PrivacyPolicy1 />
    </Layout>
  )
}

export default PrivacyPolicyPage
